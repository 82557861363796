// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-bg-js": () => import("./../src/pages/404.bg.js" /* webpackChunkName: "component---src-pages-404-bg-js" */),
  "component---src-pages-404-de-js": () => import("./../src/pages/404.de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-en-js": () => import("./../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-bg-js": () => import("./../src/pages/about.bg.js" /* webpackChunkName: "component---src-pages-about-bg-js" */),
  "component---src-pages-about-de-js": () => import("./../src/pages/about.de.js" /* webpackChunkName: "component---src-pages-about-de-js" */),
  "component---src-pages-about-en-js": () => import("./../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-bg-js": () => import("./../src/pages/contact.bg.js" /* webpackChunkName: "component---src-pages-contact-bg-js" */),
  "component---src-pages-contact-de-js": () => import("./../src/pages/contact.de.js" /* webpackChunkName: "component---src-pages-contact-de-js" */),
  "component---src-pages-contact-en-js": () => import("./../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-bg-js": () => import("./../src/pages/gallery.bg.js" /* webpackChunkName: "component---src-pages-gallery-bg-js" */),
  "component---src-pages-gallery-de-js": () => import("./../src/pages/gallery.de.js" /* webpackChunkName: "component---src-pages-gallery-de-js" */),
  "component---src-pages-gallery-en-js": () => import("./../src/pages/gallery.en.js" /* webpackChunkName: "component---src-pages-gallery-en-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-bg-js": () => import("./../src/pages/index.bg.js" /* webpackChunkName: "component---src-pages-index-bg-js" */),
  "component---src-pages-index-de-js": () => import("./../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-processes-bg-js": () => import("./../src/pages/processes.bg.js" /* webpackChunkName: "component---src-pages-processes-bg-js" */),
  "component---src-pages-processes-de-js": () => import("./../src/pages/processes.de.js" /* webpackChunkName: "component---src-pages-processes-de-js" */),
  "component---src-pages-processes-en-js": () => import("./../src/pages/processes.en.js" /* webpackChunkName: "component---src-pages-processes-en-js" */),
  "component---src-pages-processes-js": () => import("./../src/pages/processes.js" /* webpackChunkName: "component---src-pages-processes-js" */),
  "component---src-pages-sectors-bg-js": () => import("./../src/pages/sectors.bg.js" /* webpackChunkName: "component---src-pages-sectors-bg-js" */),
  "component---src-pages-sectors-de-js": () => import("./../src/pages/sectors.de.js" /* webpackChunkName: "component---src-pages-sectors-de-js" */),
  "component---src-pages-sectors-en-js": () => import("./../src/pages/sectors.en.js" /* webpackChunkName: "component---src-pages-sectors-en-js" */),
  "component---src-pages-sectors-js": () => import("./../src/pages/sectors.js" /* webpackChunkName: "component---src-pages-sectors-js" */),
  "component---src-templates-single-process-js": () => import("./../src/templates/single-process.js" /* webpackChunkName: "component---src-templates-single-process-js" */)
}

